@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Nunito&family=Nunito+Sans:wght@400;600&family=Poppins:ital,wght@0,400;0,500;1,300;1,400&family=Questrial&family=Raleway&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-image-gallery/styles/css/image-gallery.css';

/* Testing on phone offline */
@font-face {
  font-family: myFont;
  src: url('../public/Questrial-Regular.ttf') format('truetype');
}

html {
  @apply scroll-smooth;
}

*::-webkit-scrollbar {
  @apply w-[5px] h-[5px];
}

*::-webkit-scrollbar-thumb {
  @apply rounded bg-slate-400;
}

*::-webkit-scrollbar-track {
  @apply !bg-transparent;
}

body {
  @apply font-questrial bg-main-bg text-slate-700 dark:bg-main-dark dark:text-slate-300;
  font-family: myFont;
}

/* General styles */
p,
a,
svg {
  @apply text-muted;
}

a.btn {
  @apply opacity-100;
}

footer a {
  @apply text-sm transition-a;
}

footer a:hover {
  @apply ml-2 text-primary;
}

.heading {
  @apply text-3xl font-bold capitalize text-secondary dark:text-slate-300;
}

.sub-heading {
  @apply text-primary uppercase w-fit bg-primary/30 px-4 py-[3px] rounded-full mb-3 max-w-[200px];
}

.image {
  @apply group-hover:scale-125 transition-a;
}

.card {
  @apply p-4 bg-white border rounded-lg dark:bg-card-dark dark:border-dark;
}

.card-bordered {
  @apply border dark:border-dark;
}

.btn {
  @apply px-4 py-[0.35rem] rounded-lg transition-a sm:cursor-pointer !uppercase flex-shrink-0;
}

.btn-primary {
  @apply bg-primary text-white hover:bg-[#f7751e] shadow-md shadow-primary/60;
}

.btn-secondary {
  @apply bg-secondary text-white hover:bg-[#02293e];
}

.icon-box {
  @apply grid flex-shrink-0 rounded-full w-9 h-9 place-items-center hover:bg-slate-200 dark:hover:bg-hover-color-dark transition-a sm:cursor-pointer;
}

.border {
  @apply border-[#d2d2d2]/40;
}

.input {
  @apply border !border-slate-300 dark:!border-dark bg-transparent px-4 py-[0.35rem] outline-none focus:!border-primary;
}

.hide-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

/* Loader */
.loader {
  @apply w-20 h-20 border-4 rounded-full border-primary;
  border-block-start-color: transparent;
  animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.test-color {
  color: rgba(213, 211, 211, 0.192);
}
